<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_sysLogForm">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        消息标题：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MsgTitle">
                            <el-input v-model="dataSource.MsgTitle" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        消息时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="MsgTime">
                            <el-input :disabled="true" v-model="dataSource.MsgTime"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        发送人：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="OperationType">
                            <el-select :disabled="true" v-model="dataSource.OperationType" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-input :disabled="true" v-model="dataSource.OperationType"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        消息内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="MsgContent">
                            <el-input type="textarea" :rows="10" v-model="dataSource.MsgContent"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    methods: {

        showSrc() {
            var _this = this;
            _this.requestContent = _this.dataSource.RequestParam;
            _this.responseContent = _this.dataSource.OperationDesc;
            _this.isJsonBuef = true;
            _this.isEncodeBuef = true;
        },
        jonsDeal() {
            var _this = this;
            if (_this.isJsonBuef) {
                _this.isJsonBuef = false;
                try {
                    var jsonObj = JSON.parse(_this.requestContent || '');
                    _this.requestContent = JSON.stringify(jsonObj, null, 4);
                } catch (e) {
                    _this.requestContent = _this.requestContent;
                }
                try {
                    jsonObj = JSON.parse(_this.responseContent || '');
                    _this.responseContent = JSON.stringify(jsonObj, null, 4);

                } catch (e) {
                    _this.responseContent = _this.responseContent;
                }

            }
            else {
                // _this.isJsonBuef = true;
                // _this.requestContent = _this.detailSource.RequestContent;
                // _this.responseContent = _this.detailSource.ResponseContent;
            }
        },
        urldecode() {
            var _this = this;
            if (_this.isEncodeBuef) {
                _this.isEncodeBuef = false;
                _this.requestContent = decodeURIComponent(_this.requestContent);
                _this.responseContent = decodeURIComponent(_this.responseContent);
            }

        }
    }
}
</script>
